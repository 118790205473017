<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="mt-2">
                <validation-observer
                    ref="observer"
                    v-slot="{ invalid }"
                    class="mt-8"
                >
                    <form
                        class="form"
                        style="width: 100%"
                        action=""
                        @submit.prevent="updateUserInfo"
                    >
                        <div>
                            <label class="user_info_label"> 은행 이름 </label>

                            <validation-provider
                                v-slot="{ errors }"
                                name="은행 이름"
                                rules="required"
                                mode="aggressive"
                            >
                                <v-text-field
                                    v-model="user_info.bank_name"
                                    outlined
                                    :error-messages="errors"
                                    required
                                    dense
                                    v-on:keyup.enter="updateUserInfo"
                                    hint="판매된 상품의 금액을 받으실 은행"
                                ></v-text-field>
                            </validation-provider>
                        </div>

                        <div>
                            <label class="user_info_label"> 계좌번호 </label>
                            <validation-provider
                                v-slot="{ errors }"
                                name="계좌번호"
                                rules="required"
                                mode="aggressive"
                            >
                                <v-text-field
                                    v-model="user_info.bank_account"
                                    outlined
                                    :error-messages="errors"
                                    required
                                    dense
                                    v-on:keyup.enter="updateUserInfo"
                                    hint="-없이 숫자만 입력"
                                ></v-text-field>
                            </validation-provider>
                        </div>

                        <div>
                            <label class="user_info_label"> 예금주 </label>
                            <validation-provider
                                v-slot="{ errors }"
                                name="예금주"
                                rules="required"
                                mode="aggressive"
                            >
                                <v-text-field
                                    v-model="user_info.bank_holder"
                                    outlined
                                    :error-messages="errors"
                                    required
                                    dense
                                    v-on:keyup.enter="updateUserInfo"
                                ></v-text-field>
                            </validation-provider>
                        </div>

                        <div>
                            <label class="user_info_label"> 휴대폰번호 </label>
                            <validation-provider
                                v-slot="{ errors }"
                                name="휴대폰번호"
                                rules="required"
                                mode="aggressive"
                                
                            >
                                <v-text-field
                                    v-model="user_info.user_phone"
                                    outlined
                                    :error-messages="errors"
                                    required
                                    dense
                                    v-on:keyup.enter="updateUserInfo"
                                    hint="-없이 숫자만 입력"
                                ></v-text-field>
                            </validation-provider>
                        </div>

                        <v-btn
                            color="primary"
                            class="mt-6"
                            depressed
                            block
                            :disabled="invalid"
                            style="color: #ffffff"
                            @click="updateUserInfo"
                        >
                            확인
                        </v-btn>
                    </form>
                </validation-observer>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "../service/axios"
import { required } from "vee-validate/dist/rules";
setInteractionMode("eager");
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";

extend("required", {
    ...required,
    message: "{_field_} 입력해주세요",
});

export default {
    name: "VerifyUser",
    
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            invalid: true,
            user_info: {},
            items: [],
        };
    }, // data

    computed: {
        // ...mapState('G_NAME', [])
    }, // computed

    async mounted() {
        var _this = this;
        window.onpopstate = function () {
            // console.log('History : ', window.history.length)
            // for(var i=0; window.history.length>i; i++) {
            //     window.history.go(-1)
            // }
            _this.$router.replace("/");
        }

       
    }, // mounted

    methods: {
        updateUserInfo() {
            this.loading_bar = true;

            const params = {
                user_info: this.user_info,
                accessKey: this.$cookies.get("token"),
            };
            params.user_info.authentication_yn = true;
            axios
                .put(`/users/update`, params)
                .then((res) => {
                    this.user_info = res.data.data;
                    this.snackbar_msg = "정보가 수정되었습니다.";
                    this.snackbar = true;
                    this.selectUserData();
                })
                .catch((error) => {
                    this.loading_bar = false;
                    console.log("페스트 오류", error);
                });
        },

        selectUserData() {
            const params = {
                accessKey: this.$cookies.get("token"),
            };
            axios
                .post("/users/info", params)
                .then((res) => {
                    console.log("user info....");
                    let userData = res.data.data.item;
                    this.$store.commit({ type: "setUserData", userData: userData })
                    this.loading_bar = false;

                    this.$router.go(-1)
                })
                .catch((error) => {
                    console.log("페스트 오류", error);
                    this.loading_bar = false;
                });
        },
    }, // methods
}; // export
</script>

<style scoped>
</style>
